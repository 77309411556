<template>
  <div class="walker-specification-search">
    <fmmp-autocomplete
        class="search-field"
        :config="autocompleteConfig"
        :id="descField"
        :name="descField"
        :items="fieldsConfig[descField].items"
        :disabled="fieldsConfig[descField].disabled"
        v-model="fieldsConfig[descField].model"
        :placeholder="getTranslation(fieldsConfig[descField].placeholder)"
        :loading="false"
        @input="(value) => handleFieldInput(descField, value)"
    ></fmmp-autocomplete>
    <button
        class="button-main specification-search-button"
        v-if="searchButton && fieldsConfig[descField].model"
        :disabled="searchButton.isDisabled"
        @click="handleSearch"
    >
      <fmmp-i18n text="search" />
    </button>
  </div>
</template>

<script>
import configs from "../../../../driv-part-finder/common/specification-search/SpecificationSearchConfig";
import {
  getBrandsOptionsFromString,
  getModelFromFieldObject,
  resetField,
} from "../../../../driv-part-finder/common/specification-search/helpers";
import {
  FIELDS,
  PART_DESCRIPTION_ATTRIBUTE_NAME,
} from "../../../../driv-part-finder/common/specification-search/constants";
import { getOptionsConfigBySelectedApplicationTypes } from "../../../../driv-part-finder/common/ymm-search/helpers";
import { SPECIFICATION_PROPERTIES } from "../../../../driv-part-finder/driv-part-finder-part-results-page/clientlibs/src/constants"
import {
  createUrlToGoToSearchResults,
  getTranslation,
} from "../../../../driv-part-finder/common/partFinderCorporate.helpers";
import {
  PART_FINDER_CORPORATE_SEARCH_TYPES
} from "../../../../driv-part-finder/common/partFinderCorporate.constants";

const OPTIONS = {
  initialFieldItems: JSON.stringify([{"applicationType":"Parts Specification ","groupId":"11"}]),
  brandTypes: JSON.stringify([{"brandName":"Walker Exhaust","brandCode":"BDCN"}])
}

const { fieldsConfig, fieldsOptionsFromAEM, optionsConfig, searchButton, initialField } = configs;

export default Vue.component("walker-specification-search", {
  data() {
    return {
      autocompleteConfig: {
        minLength: 0,
        delay: 0,
        html: false,
        maxHeight: "300px",
        onlySelectValid: true,
        searchOnFocus: false,
      },
      optionsConfig,
      fieldsConfig,
      searchButton,
      initialField,
      FIELDS,
      descField: FIELDS.PART_DESCRIPTION,
      options: OPTIONS
    };
  },
  props: {
    resultsPagePath: {
      type: String,
      default: "",
    },
    selectedPartDesc: {
      value: String,
      default: "",
    }
  },
  computed: {
    config() {
      return getOptionsConfigBySelectedApplicationTypes({
        optionsConfig: this.optionsConfig,
        type: this.initialField.model,
      });
    },
  },
  watch: {
    "initialField.items": function (newItems) {
      if (newItems.length === 1) {
        this.initialField.model = newItems[0];
        this.initialField.onInput(newItems[0]);
      }
    },
    'fieldsConfig.part_description.items': function (newItems) {
      if(newItems.length && this.selectedPartDesc) {
        const selectedPartDescOption = newItems.find(option => option.value === this.selectedPartDesc)

        if(selectedPartDescOption) {
          fieldsConfig[this.descField].model = selectedPartDescOption
        }
      }
    }
  },
  methods: {
    getTranslation,
    handleFieldInput(field, value) {
      this.config.fields[field].onInput(value);
    },
    handleSearch() {
      if (this.resultsPagePath) {
        const specificationType = getModelFromFieldObject(this.initialField);
        const brands = getModelFromFieldObject(this.fieldsConfig[FIELDS.BRAND]);
        const partTypeId = getModelFromFieldObject(this.fieldsConfig[FIELDS.PART_TYPE]);
        const partDescription = getModelFromFieldObject(
            this.fieldsConfig[FIELDS.PART_DESCRIPTION],
        );

        const queryObj = {
          searchType: PART_FINDER_CORPORATE_SEARCH_TYPES.SPECIFICATION,
          specificationType,
          partTypeId,
          brands,
          contentSilos: {},
        };

        const hashString = {
          [SPECIFICATION_PROPERTIES.ATTRIBUTE_FILTERS]: [
            {
              name: PART_DESCRIPTION_ATTRIBUTE_NAME,
              value: partDescription.value,
            },
          ],
        }

        const url = createUrlToGoToSearchResults({
          queryObj,
          path: this.resultsPagePath,
          hashString,
        });

        const eventParams = Object.values(queryObj).reduce((acc, item, index, initialArr) => {
            if (item.label) {
              acc = [...acc, item.label];
            }

            if(initialArr.length - 1 === index) {
              return [...acc, partDescription.value]
            }

            return [...acc];
        }, []);

        Vue.Global.Analytics.trackEvent(
            "part-search",
            "search-part-filter",
            eventParams.join("|"),
            null,
            {
              callback: function () {
                window.location.assign(url);
              },
            },
        );
      }

      return false;
    },
  },
  created() {
    initialField.getItems(this.options.initialFieldItems);

    fieldsOptionsFromAEM[FIELDS.BRAND] = getBrandsOptionsFromString(
        this.options.brandTypes,
    );
  },
  beforeDestroy() {
    resetField(initialField);
  },
});
</script>
