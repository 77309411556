import { ATTRIBUTES, FIELDS, BRAND_TYPE_KEYS } from "./constants";
import { APPLICATION_TYPE_KEYS } from "../partFinderCorporate.constants";

export const getOptionsFromResponse = (valuePropName, labelPropName) => (item) => ({
    value: item[valuePropName],
    label: item[labelPropName],
});

export const resetField = (value) => {
    value.items = [];
    value.model = "";
    value.disabled = true;
};

export const getModelFromFieldObject = (field) => {
    return field?.model || {};
};

export const getResultField = (response, fieldKey) => {
    if (fieldKey === FIELDS.PART_DESCRIPTION) {
        return response[ATTRIBUTES].map((attr) => ({
            value: attr,
            label: attr,
        }));
    }
    return response[fieldKey];
};

export const getBrandsOptionsFromString = (brandsString) => {
    const brandTypes = JSON.parse(brandsString);
    return brandTypes.map(getOptionsFromResponse(BRAND_TYPE_KEYS.value, BRAND_TYPE_KEYS.label));
};

export const getTorqueContentSilosFromString = (silosString) => {
    const contentSilos = JSON.parse(silosString);
    return contentSilos.map(
        getOptionsFromResponse(APPLICATION_TYPE_KEYS.value, APPLICATION_TYPE_KEYS.label),
    );
};
