import { getQsParams } from "../../../driv-part-finder/driv-part-finder-part-results-page/clientlibs/src/helpers";

export const getInitialFieldConfig = (placeholder) => ({
    items: [],
    model: "",
    placeholder,
    disabled: true,
    loading: false,
    visible: true
});

export const getInitialFieldConfigEmission = (placeholder) => ({
    items: [],
    model: "",
    placeholder,
    disabled: true,
    loading: false,
    visible: false
});

export const getApplicationTypesOptionsFromResponse = (valuePropName, labelPropName) => (item) => ({
    value: `${item[valuePropName]}`
        .replace(/\s|[ ,]+$/g, "")
        .split(",")
        .sort((a, b) => a - b)
        .join(","),
    label: item[labelPropName],
});

const GPI_API_PATH = ".gpi.";

export const createUrlToGoToSearchResults = ({ queryObj, path, hashString }) => {
    let updatedPath = path;
    const urlOrigin = window.location.origin;
    const { signed_request } = getQsParams();
    const signedRequest = document.getElementById("signed_request")?.value || signed_request;

    const newQueryObject = {
        ...queryObj,
        ...(signedRequest
            ? {
                  signed_request: signedRequest,
              }
            : {}),
    };

    const queryString = Qs.stringify(newQueryObject, {
        encodeValuesOnly: true,
    });

    if (window.brand === "gpi" && updatedPath.includes(GPI_API_PATH)) {
        updatedPath = updatedPath.replace(GPI_API_PATH, "");
    }

    const urlPath = updatedPath.startsWith("/") ? updatedPath : `/${updatedPath}`;

    return `${urlOrigin}${urlPath}.html?${queryString}${
        hashString ? "#" + Qs.stringify(hashString) : ""
    }`;
};

const removeSpaces = (value) => value.replace(/\s*/gm, "");

export const getOptionsFromResponse = (valuePropName, labelPropName) => (item) => ({
    value: item[valuePropName],
    label: item[labelPropName],
});

export const resetField = (value) => {
    value.items = [];
    value.model = "";
    value.disabled = true;
};

export const getModelFromFieldObject = (field) => {
    return field?.model || {};
};

export const getOptionsConfigBySelectedApplicationTypes = ({ optionsConfig, type }) => {
    return optionsConfig;
};

export const getEmissionData = (partTypeData, applicationType, year ) => {
    let applicableCertificates = [];
    const partData = partTypeData.find((type) => {
        return type['catalogValue'] == applicationType;
    });
    year = Number(year);
    
    const emissionCertificates = partData && partData.emissionCertificates ? partData.emissionCertificates.filter((item) => {
        if (item.certificateEndDate) {
            return year >= item.certificateStartDate.year && year <= item.certificateEndDate.year ;
        }
        else {
            return year >= item.certificateStartDate.year ;
        }
    }): [];
    
    emissionCertificates.length ? emissionCertificates.forEach(element => {
        const certificate = {
            'value': element.certificateLabel,
            'id' : element.certificateKey
        };
        applicableCertificates.push(certificate);
    }) : [];
    return applicableCertificates;
};

export const PAGE_BRAND_TAG_PROPERTY = "brandcodepart";

export const getBrandsFromTags = (tags) => {
    if (tags) {
        const tagsArray = tags.split(",");
        const brandTags = tagsArray.reduce((acc, item) => {
            if (item.includes(PAGE_BRAND_TAG_PROPERTY)) {
                const brand = item.split(":")[1];
                return `${acc ? acc + "," : acc}${brand}`;
            }

            return acc;
        }, "");

        return brandTags || "";
    }
    return "";
};
