<template>
  <div class="walker-specification-search-results-content">
    <div class="walker-specification-search-results-content-search">
      <walker-specification-search :selectedPartDesc="selectedPartDesc" :resultsPagePath="resultsPagePath"></walker-specification-search>
    </div>

    <driv-part-finder-part-results :detailsPath="detailsPagePath"></driv-part-finder-part-results>
  </div>
</template>

<script>
import {
  getHashParams,
} from "../../../../driv-part-finder/driv-part-finder-part-results-page/clientlibs/src/helpers";
import {
  FILTERS_QS_OPTIONS, SPECIFICATION_PROPERTIES
} from "../../../../driv-part-finder/driv-part-finder-part-results-page/clientlibs/src/constants";

export default Vue.component("walker-specification-search-results", {
  data() {
    return {
      selectedPartDesc: '',
    };
  },
  props: {
    resultsPagePath: {
      type: String,
      default: "",
    },
    detailsPagePath: {
      type: String,
      default: "",
    },
  },
  created() {
    const hashObj = getHashParams(FILTERS_QS_OPTIONS);

    this.selectedPartDesc = hashObj[SPECIFICATION_PROPERTIES.ATTRIBUTE_FILTERS][0].value
  },
});
</script>
