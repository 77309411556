<template>
    <div class="ymm-search-container">
        <fmmp-autocomplete
            v-if="isApplicationTypeShown"
            :class="`ymm-search-field ${initialField.name}`"
            :config="autocompleteConfig"
            :id="initialField.name"
            :name="initialField.name"
            :items="initialField.items"
            v-model="initialField.model"
            :placeholder="getTranslation(initialField.placeholder)"
            :loading="initialField.loading"
            @input="(value) => initialField.onInput(value, subBrand)"
        ></fmmp-autocomplete>
        <div v-if="initialField.model" class="ymm-search-fields-wrapper">
            <div :class="{ 'ymm-fields-only': !isApplicationTypeShown }">
                <fmmp-autocomplete
                    v-for="field in options"
                    :key="field"
                    :class="`ymm-search-field ${field}`"
                    :config="autocompleteConfig"
                    :id="field"
                    :name="field"
                    :items="fieldsConfig[field].items"
                    :disabled="fieldsConfig[field].disabled"
                    v-model="fieldsConfig[field].model"
                    v-if="fieldsConfig[field].visible"
                    :placeholder="getTranslation(fieldsConfig[field].placeholder)"
                    :loading="fieldsConfig[field].loading"
                    @input="(value) => handleFieldInput(field, value)"
                >
                    <fmmp-i18n text="initialField.placeholder" />
                </fmmp-autocomplete>
            </div>
            <button
                class="button-main ymm-search-button"
                v-if="searchButton"
                :disabled="searchButton.isDisabled"
                @click="handleSearch"
            >
                <fmmp-i18n v-if="typeof searchBtnLabel === 'string'" :text="searchBtnLabel" />
                <template v-else-if="searchBtnLabel.mobile && searchBtnLabel.desktop">
                    <fmmp-i18n class="mobile" :text="searchBtnLabel.mobile" />
                    <fmmp-i18n class="desktop" :text="searchBtnLabel.desktop" />
                </template>
                <fmmp-i18n v-else text="search" />
            </button>
        </div>
    </div>
</template>

<script>
import configs from "./YMMWalkerSearchConfig";
import {
    getBrandsFromTags,
    getModelFromFieldObject,
    getOptionsConfigBySelectedApplicationTypes,
    resetField,
    createUrlToGoToSearchResults,
} from "./helpers";
import { FIELDS } from "./constants";

const { fieldsConfig, optionsConfig, searchButton, initialField, setPartTypeData } = configs;

export default Vue.component("walker-common-ymm-search", {
    data() {
        return {
            autocompleteConfig: {
                minLength: 0,
                delay: 0,
                html: false,
                maxHeight: "300px",
                onlySelectValid: true,
                searchOnFocus: false,
            },
            optionsConfig,
            fieldsConfig,
            searchButton,
            initialField,
            isApplicationTypeShown: true,
            subBrand: "",
        };
    },
    props: {
        path: {
            type: String,
            default: "",
        },
        types: {
            type: String,
            default: "",
        },
        vehiclegroupids: {
            type: String,
            default: "",
        },
        tags: {
            type: String,
            default: "",
        },
        initialTypeId: {
            type: String,
            default: "",
        },
        searchBtnLabel: {
            type: [String, Object],
            default: "search",
        },
        searchType: {
            type: String,
            default: "",
        },
    },
    computed: {
        config() {
            return getOptionsConfigBySelectedApplicationTypes({
                optionsConfig: this.optionsConfig,
                type: this.initialField.model,
            });
        },
        options() {
            return Object.keys(this.config.fields).filter((option) => fieldsConfig[option]);
        },
    },
    watch: {
        "initialField.items": function (newItems) {
            if (newItems.length === 1) {
                this.initialField.model = newItems[0];
                this.isApplicationTypeShown = false;
                this.initialField.onInput(newItems[0], this.subBrand);
            } else if (this.initialTypeId) {
                const option = newItems.find(({ value }) => value === this.initialTypeId);
                if (option) {
                    this.initialField.model = option;
                    this.initialField.onInput(option, this.subBrand);
                }
            }
        },
    },
    methods: {
        handleFieldInput(field, value) {
            this.config.fields[field].onInput(value, this.subBrand);
        },
        getTranslation(value) {
            return Vue.i18n.get(value);
        },
        handleSearch() {
            if (this.path) {
                const type = {value: this.vehiclegroupids, label: getModelFromFieldObject(this.initialField).label};
                const year = getModelFromFieldObject(this.fieldsConfig[FIELDS.YEAR]);
                const make = getModelFromFieldObject(this.fieldsConfig[FIELDS.MAKE]);
                const model = getModelFromFieldObject(this.fieldsConfig[FIELDS.MODEL]);
                const vehicle = getModelFromFieldObject(this.fieldsConfig[FIELDS.VEHICLE]);
                const mfr = getModelFromFieldObject(this.fieldsConfig[FIELDS.MANUFACTURER]);
                const catalogId = getModelFromFieldObject(this.initialField);
                const emissionCertificateId= getModelFromFieldObject(this.fieldsConfig[FIELDS.EMISSION]);
                const equipmentModel = getModelFromFieldObject(
                    this.fieldsConfig[FIELDS.EQUIPMENT_MODEL],
                );
                const equipmentYear = getModelFromFieldObject(
                    this.fieldsConfig[FIELDS.EQUIPMENT_YEAR],
                );
                const queryObj = {
                    type,
                    ...(year?.value ? { year } : {}),
                    ...(make?.value ? { make } : {}),
                    ...(model?.value ? { model } : {}),
                    ...(vehicle?.value ? { vehicle } : {}),
                    ...(mfr?.value ? { mfr } : {}),
                    ...(catalogId?.value ? { catalogId } : {}),
                    ...(equipmentModel?.value ? { equipmentModel } : {}),
                    ...(emissionCertificateId?.value ? { emissionCertificateId } : {}),
                    ...(equipmentYear?.value ? { equipmentYear } : {}),
                    ...(this.searchType ? { searchType: this.searchType } : {}),
                };

                if (this.subBrand) {
                    queryObj.subBrand = this.subBrand;
                }

                const url = createUrlToGoToSearchResults({ queryObj, path: this.path });

                const eventParams = [
                    "",
                    type.value,
                    Object.values(queryObj)
                        .map((item) => item.label)
                        .join("~"),
                    Object.values(queryObj)
                        .map((item) => item.value)
                        .join("~"),
                ].join("|");

                Vue.Global.Analytics.trackEvent(
                    "part-search",
                    "search-part-filter",
                    eventParams,
                    null,
                    {
                        callback: function () {
                            window.location.assign(url);
                        },
                    },
                );
            }
        },
    },
    created() {
        this.subBrand = getBrandsFromTags(this.tags);

        if (this.types) {
            initialField.getItems(this.types);
            setPartTypeData(JSON.parse(this.types), this.vehiclegroupids);
        }
    },
    beforeDestroy() {
        resetField(initialField);
    },
});
</script>
