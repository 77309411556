var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "walker-specification-search" },
    [
      _c("fmmp-autocomplete", {
        staticClass: "search-field",
        attrs: {
          config: _vm.autocompleteConfig,
          id: _vm.descField,
          name: _vm.descField,
          items: _vm.fieldsConfig[_vm.descField].items,
          disabled: _vm.fieldsConfig[_vm.descField].disabled,
          placeholder: _vm.getTranslation(
            _vm.fieldsConfig[_vm.descField].placeholder
          ),
          loading: false
        },
        on: {
          input: function(value) {
            return _vm.handleFieldInput(_vm.descField, value)
          }
        },
        model: {
          value: _vm.fieldsConfig[_vm.descField].model,
          callback: function($$v) {
            _vm.$set(_vm.fieldsConfig[_vm.descField], "model", $$v)
          },
          expression: "fieldsConfig[descField].model"
        }
      }),
      _vm._v(" "),
      _vm.searchButton && _vm.fieldsConfig[_vm.descField].model
        ? _c(
            "button",
            {
              staticClass: "button-main specification-search-button",
              attrs: { disabled: _vm.searchButton.isDisabled },
              on: { click: _vm.handleSearch }
            },
            [_c("fmmp-i18n", { attrs: { text: "search" } })],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }