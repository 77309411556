import { getInitialFieldConfig, getInitialFieldConfigEmission } from "./helpers";

export const FIELDS = {
    YEAR: "years",
    EMISSION: "emission",
    MAKE: "makes",
    MODEL: "models",
    APPLICATION_TYPE: "application_type",
};

export const INITIAL_FIELDS_CONFIG = {
    [FIELDS.APPLICATION_TYPE]: getInitialFieldConfig("Application type"),
    [FIELDS.YEAR]: getInitialFieldConfig("Year"),
    [FIELDS.EMISSION]: getInitialFieldConfigEmission("Emission"),
    [FIELDS.MAKE]: getInitialFieldConfig("Make"),
    [FIELDS.MODEL]: getInitialFieldConfig("Model"),
};

export const APPLICATION_TYPE_KEYS = {
    label: "catalogName",
    value: "catalogValue",
};

export const EMPTY_ITEM = {
    value: "",
    label: "---",
};
