import {
    FIELDS,
    INITIAL_FIELDS_CONFIG,
    EMPTY_ITEM,
    PART_DESCRIPTION_ATTRIBUTE_NAME,
} from "./constants";
import { getOptionsConfigBySelectedApplicationTypes } from "../ymm-search/helpers";
import { getOptionsFromResponse, resetField, getResultField } from "./helpers";
import { getQsParams } from "../../driv-part-finder-part-results-page/clientlibs/src/helpers";
import {
    APPLICATION_TYPE_KEYS,
    SPECIFICATION_PARTS,
    SPECIFICATION_TORQUE,
} from "../partFinderCorporate.constants";

import { isGPI } from "../partFinderCorporate.helpers";

const VALUE_PROPERTY = "id";
const LABEL_PROPERTY = "value";
const fieldsConfig = INITIAL_FIELDS_CONFIG;

const fieldsOptionsFromAEM = Object.keys(fieldsConfig).reduce((acc, fieldKey) => {
    acc[fieldKey] = null;
    return acc;
}, {});

const resetDependentFields = (field) => {
    const config = getOptionsConfigBySelectedApplicationTypes({
        optionsConfig,
        type: initialField.model,
    });
    const fieldNames = Object.keys(config.fields);
    const dependentFieldsStart = fieldNames.indexOf(field);
    const dependentFieldNames = fieldNames.slice(dependentFieldsStart);

    dependentFieldNames.forEach((field) => {
        resetField(fieldsConfig[field]);
    });

    searchButton.isDisabled = true;
};

const resolveNextDependentFields = (currentField) => {
    if (fieldsConfig[currentField]?.model?.value) {
        const fieldValue = fieldsConfig[currentField].model;
        const config = getOptionsConfigBySelectedApplicationTypes({
            optionsConfig,
            type: initialField.model,
        });

        config.fields[currentField].onInput(fieldValue, true);
    }
};

const resolveDependentFields = (field) => (value, includeDependentFieldsItems) => {
    if (!includeDependentFieldsItems) {
        resetDependentFields(field);
    }
    fieldsConfig[field].disabled = false;

    FIELDS_ACTIONS[field](value, includeDependentFieldsItems);
};

const enableSearchButton = () => {
    searchButton.isDisabled = false;
};

const setModelAndEmitAction = (field, resolveNextDeps) => {
    fieldsConfig[field].model = fieldsConfig[field].items[0];
    const config = getOptionsConfigBySelectedApplicationTypes({
        optionsConfig,
        type: initialField.model,
    });

    config.fields[field].onInput(fieldsConfig[field].items[0], resolveNextDeps);
};

/* Handlers */

const onApplicationTypeInput = (type, includeDependentFieldsItems) => {
    const config = getOptionsConfigBySelectedApplicationTypes({ optionsConfig, type });

    if (config) {
        config.onInput(type, includeDependentFieldsItems);
    }
};

/* Get data */

const getData = (fieldKey, fieldApiService, queryData, resolveNextDeps) => {
    fieldApiService(queryData)
        .then((res) => {
            const resField = getResultField(res, fieldKey);

            if (!resField?.length) {
                fieldsConfig[fieldKey].items = [EMPTY_ITEM];
                setModelAndEmitAction(fieldKey, resolveNextDeps);
            } else {
                fieldsConfig[fieldKey].items =
                    fieldKey === FIELDS.PART_DESCRIPTION
                        ? resField
                        : resField.map(getOptionsFromResponse(VALUE_PROPERTY, LABEL_PROPERTY));
                if (resField.length === 1) {
                    setModelAndEmitAction(fieldKey, resolveNextDeps);
                } else if (resolveNextDeps) {
                    resolveNextDependentFields(fieldKey);
                }
            }
        })
        .catch((e) => {
            console.error(e);
            fieldsConfig[fieldKey].items = [];
        })
        .finally(() => {
            fieldsConfig[fieldKey].loading = false;

            setTimeout(() => {
                const eleContainer = document.querySelector('.' + fieldKey);
                const isvalueSelected = eleContainer.querySelector('.selected-tag');
                if(isGPI() && !isvalueSelected) {
                    eleContainer.querySelector('input').focus();
                }
            }, 0);
        });
};

const getSpecificationTypes = (typesString) => {
    initialField.loading = true;

    const specificationTypes = JSON.parse(typesString);

    Promise.resolve(
        specificationTypes.map(
            getOptionsFromResponse(APPLICATION_TYPE_KEYS.value, APPLICATION_TYPE_KEYS.label),
        ),
    )
        .then((res) => (initialField.items = res))
        .finally(() => {
            initialField.loading = false;
        });
};

const getSpecificationBrands = (value, resolveNextDeps) => {
    const fieldKey = FIELDS.BRAND;
    const resField = fieldsOptionsFromAEM[FIELDS.BRAND];

    fieldsConfig[fieldKey].loading = true;
    fieldsConfig[fieldKey].items = resField;

    if (!resField?.length) {
        fieldsConfig[fieldKey].items = [EMPTY_ITEM];
        setModelAndEmitAction(fieldKey, resolveNextDeps);
    } else {
        if (resField.length === 1) {
            setModelAndEmitAction(fieldKey, resolveNextDeps);
        } else if (resolveNextDeps) {
            resolveNextDependentFields(fieldKey);
        }
    }

    fieldsConfig[fieldKey].loading = false;
};

const getSpecificationPartType = (value, resolveNextDeps) => {
    const brandCode = value?.value;

    fieldsConfig[FIELDS.PART_TYPE].loading = true;

    getData(
        FIELDS.PART_TYPE,
        Vue.CatalogApi.CatalogApiService.getSpecificationPartType,
        brandCode,
        resolveNextDeps,
    );
};

const getSpecificationPartDescription = (value, resolveNextDeps) => {
    const partTypeId = value?.value;
    const brandCodes = fieldsConfig[FIELDS.BRAND].model.value;
    const query = { partTypeId, brandCodes, attributeName: PART_DESCRIPTION_ATTRIBUTE_NAME };

    fieldsConfig[FIELDS.PART_DESCRIPTION].loading = true;

    getData(
        FIELDS.PART_DESCRIPTION,
        Vue.CatalogApi.CatalogApiService.getSpecificationPartDescription,
        query,
        resolveNextDeps,
    );
};

const getTorqueContentSilos = (value, resolveNextDeps) => {
    const fieldKey = FIELDS.TORQUE_CONTENT_SILOS;
    const resField = fieldsOptionsFromAEM[FIELDS.TORQUE_CONTENT_SILOS];
    const { contentSilos } = getQsParams();

    fieldsConfig[fieldKey].loading = true;
    fieldsConfig[fieldKey].items = resField;

    if (contentSilos) {
        fieldsConfig[fieldKey].model = contentSilos;
    }

    if (!resField?.length) {
        fieldsConfig[fieldKey].items = [EMPTY_ITEM];
        setModelAndEmitAction(fieldKey, resolveNextDeps);
    } else {
        if (resField.length === 1) {
            setModelAndEmitAction(fieldKey, resolveNextDeps);
        } else if (resolveNextDeps) {
            resolveNextDependentFields(fieldKey);
        }
    }

    fieldsConfig[fieldKey].loading = false;
};

const FIELDS_ACTIONS = {
    [FIELDS.BRAND]: getSpecificationBrands,
    [FIELDS.PART_TYPE]: getSpecificationPartType,
    [FIELDS.PART_DESCRIPTION]: getSpecificationPartDescription,
    [FIELDS.TORQUE_CONTENT_SILOS]: getTorqueContentSilos,
};

/* Configs */

const optionsConfig = {
    [SPECIFICATION_PARTS]: {
        onInput: resolveDependentFields(FIELDS.BRAND),
        fields: {
            [FIELDS.BRAND]: {
                onInput: resolveDependentFields(FIELDS.PART_TYPE),
            },
            [FIELDS.PART_TYPE]: {
                onInput: resolveDependentFields(FIELDS.PART_DESCRIPTION),
            },
            [FIELDS.PART_DESCRIPTION]: {
                onInput: enableSearchButton,
            },
        },
    },
    [SPECIFICATION_TORQUE]: {
        onInput: resolveDependentFields(FIELDS.TORQUE_CONTENT_SILOS),
        fields: {
            [FIELDS.TORQUE_CONTENT_SILOS]: {
                onInput: enableSearchButton,
            },
        },
    },
};

const searchButton = {
    isDisabled: true,
};

const initialField = {
    ...INITIAL_FIELDS_CONFIG[FIELDS.TYPE],
    name: FIELDS.TYPE,
    getItems: getSpecificationTypes,
    onInput: onApplicationTypeInput,
};

export default {
    fieldsConfig,
    optionsConfig,
    searchButton,
    initialField,
    fieldsOptionsFromAEM,
};
