var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "walker-specification-search-results-content" },
    [
      _c(
        "div",
        { staticClass: "walker-specification-search-results-content-search" },
        [
          _c("walker-specification-search", {
            attrs: {
              selectedPartDesc: _vm.selectedPartDesc,
              resultsPagePath: _vm.resultsPagePath
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("driv-part-finder-part-results", {
        attrs: { detailsPath: _vm.detailsPagePath }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }