import { FIELDS, INITIAL_FIELDS_CONFIG, EMPTY_ITEM, APPLICATION_TYPE_KEYS } from "./constants";
import {
    getOptionsConfigBySelectedApplicationTypes,
    getOptionsFromResponse,
    resetField,
    getApplicationTypesOptionsFromResponse,
    getEmissionData,
    getModelFromFieldObject,
} from "./helpers";

const VALUE_PROPERTY = "id";
const LABEL_PROPERTY = "value";
const { LOOK_UP_IDS } = Vue.PartFinder;
let partTypeData = [], typeId= '', applicationType;

const resetDependentFields = (field) => {
    const config = getOptionsConfigBySelectedApplicationTypes({
        optionsConfig,
        type: initialField.model,
    });
    const fieldNames = Object.keys(config.fields);
    const dependentFieldsStart = fieldNames.indexOf(field);
    const dependentFieldNames = fieldNames.slice(dependentFieldsStart);

    dependentFieldNames.forEach((field) => {
        resetField(fieldsConfig[field]);
    });

    searchButton.isDisabled = true;
};

const resolveNextDependentFields = (currentField, subBrand) => {
    if (fieldsConfig[currentField]?.model?.value) {
        const fieldValue = fieldsConfig[currentField].model;
        const config = getOptionsConfigBySelectedApplicationTypes({
            optionsConfig,
            type: initialField.model,
        });

        config.fields[currentField].onInput(fieldValue, subBrand, true);
    }
};

const resolveDependentFields = (field) => (value, subBrand, includeDependentFieldsItems) => {
    if (field == FIELDS.EMISSION) {
        const emissionData = getEmissionData(partTypeData, applicationType, value?.value);
        !includeDependentFieldsItems && resetDependentFields(field);
        if (emissionData.length) {
            fieldsConfig[FIELDS.EMISSION].visible = true;
            fieldsConfig[FIELDS.EMISSION].disabled = false;
            FIELDS_ACTIONS[FIELDS.EMISSION](value, subBrand, includeDependentFieldsItems, emissionData);
        }
        else{
            fieldsConfig[FIELDS.MAKE].disabled = false;
            fieldsConfig[FIELDS.EMISSION].visible = false;
            FIELDS_ACTIONS[FIELDS.MAKE](value, subBrand, includeDependentFieldsItems);
        }
        
    }
    else {
        if (fieldsConfig[field]?.model) {
            !includeDependentFieldsItems && resetDependentFields(field);
        }
        fieldsConfig[field].disabled = false;
        // when Emission certificate selected explicitely pass year value. in else it will automatically go.
        if ( field == FIELDS.MAKE) {
            FIELDS_ACTIONS[field](fieldsConfig[FIELDS.YEAR].model, subBrand, includeDependentFieldsItems);
        }
        else{
            // code for model, to pass emission key
            FIELDS_ACTIONS[field](value, subBrand, includeDependentFieldsItems);
        }
    }
};

const enableSearchButton = () => {
    searchButton.isDisabled = false;
};

const setModelAndEmitAction = (field, resolveNextDeps) => {
    fieldsConfig[field].model = fieldsConfig[field].items[0];
    const config = getOptionsConfigBySelectedApplicationTypes({
        optionsConfig,
        type: initialField.model,
    });

    config.fields[field].onInput(fieldsConfig[field].items[0], null, resolveNextDeps);
};

/* Handlers */

const onApplicationTypeInput = (type, subBrand, includeDependentFieldsItems) => {
    const config = getOptionsConfigBySelectedApplicationTypes({ optionsConfig, type });
    applicationType = type?.value;

    if (config) {
        config.onInput(type, subBrand, includeDependentFieldsItems);
        fieldsConfig[FIELDS.EMISSION].disabled = true;
        fieldsConfig[FIELDS.EMISSION].visible = false;
        return;
    }
};

/* Get data */

const getMakes = (value, subBrand, resolveNextDeps) => {
    const catalogId = initialField.model.value;
    const yearId = value?.value;
    const emissionCertificateId = fieldsConfig[FIELDS.EMISSION].model.value;

    fieldsConfig[FIELDS.MAKE].loading = true;

    Vue.CatalogApi.CatalogApiService.getMakes({ typeId, yearId, subBrand, catalogId, emissionCertificateId })
        .then((res) => {
            if (res.length === 1) {
                fieldsConfig[FIELDS.MAKE].model = res[0];
                fieldsConfig[FIELDS.MAKE].onInput(res[0], subBrand, resolveNextDeps);
            } else {
                fieldsConfig[FIELDS.MAKE].items = res[FIELDS.MAKE].map(
                    getOptionsFromResponse(VALUE_PROPERTY, LABEL_PROPERTY),
                );
                resolveNextDeps && resolveNextDependentFields(FIELDS.MAKE, subBrand);
            }
        })
        .catch(() => {
            fieldsConfig[FIELDS.MAKE].items = [];
        })
        .finally(() => {
            fieldsConfig[FIELDS.MAKE].loading = false;
        });
};

const getYears = (value, subBrand, resolveNextDeps) => {
    const catalogId = value?.value;

    fieldsConfig[FIELDS.YEAR].loading = true;

    Vue.CatalogApi.CatalogApiService.getYears({ typeId, subBrand, catalogId})
        .then((res) => {
            if (res.length === 1) {
                fieldsConfig[FIELDS.YEAR].model = res[0];
                fieldsConfig[FIELDS.YEAR].onInput(res[0], subBrand, resolveNextDeps);
            } else {
                fieldsConfig[FIELDS.YEAR].items = res[FIELDS.YEAR].map(
                    getOptionsFromResponse(VALUE_PROPERTY, LABEL_PROPERTY),
                );
                resolveNextDeps && resolveNextDependentFields(FIELDS.YEAR, subBrand);
            }
        })
        .catch(() => {
            fieldsConfig[FIELDS.YEAR].items = [];
        })
        .finally(() => {
            fieldsConfig[FIELDS.YEAR].loading = false;
        });
};

const getEmission = (value, subBrand, resolveNextDeps, emissionCertificates) => {
    if (emissionCertificates.length <= 0) {
        fieldsConfig[FIELDS.EMISSION].items = [];
        fieldsConfig[FIELDS.EMISSION].visible = false;
    }
    else{
        fieldsConfig[FIELDS.EMISSION].items = emissionCertificates.map(
            getOptionsFromResponse(VALUE_PROPERTY, LABEL_PROPERTY),
        );
        resolveNextDeps && resolveNextDependentFields(FIELDS.EMISSION, subBrand);
    }
}; 


const getModels = (value, subBrand, resolveNextDeps) => {
    const catalogId = initialField.model.value;
    const yearId = fieldsConfig[FIELDS.YEAR].model.value;
    const makeId = value?.value;
    const emissionCertificateId = fieldsConfig[FIELDS.EMISSION].model.value;

    fieldsConfig[FIELDS.MODEL].loading = true;

    Vue.CatalogApi.CatalogApiService.getModels({
        typeId,
        yearId,
        makeId,
        subBrand,
        catalogId,
        emissionCertificateId,
    })
        .then((res) => {
            if (res.length === 1) {
                fieldsConfig[FIELDS.MODEL].model = res[0];
                fieldsConfig[FIELDS.MODEL].onInput(res[0], subBrand, resolveNextDeps);
            } else {
                fieldsConfig[FIELDS.MODEL].items = res[FIELDS.MODEL].map(
                    getOptionsFromResponse(VALUE_PROPERTY, LABEL_PROPERTY),
                );
                resolveNextDeps && resolveNextDependentFields(FIELDS.MODEL, subBrand);
            }
        })
        .catch(() => {
            fieldsConfig[FIELDS.MODEL].items = [];
        })
        .finally(() => {
            fieldsConfig[FIELDS.MODEL].loading = false;
        });
};
const FIELDS_ACTIONS = {
    [FIELDS.YEAR]: getYears,
    [FIELDS.MAKE]: getMakes,
    [FIELDS.EMISSION]: getEmission,
    [FIELDS.MODEL]: getModels,
};

const getApplicationTypes = (applicationTypesString, subBrand) => {
    initialField.loading = true;

    const applicationTypes = JSON.parse(applicationTypesString);

    Promise.resolve(
        applicationTypes.map(
            getApplicationTypesOptionsFromResponse(
                APPLICATION_TYPE_KEYS.value,
                APPLICATION_TYPE_KEYS.label,
            ),
        ),
    )
        .then((res) => (initialField.items = res))
        .finally(() => {
            initialField.loading = false;
        });
};

const setPartTypeData = (data, ids) => {
    partTypeData = data;
    typeId = ids;
};
/* Configs */

const fieldsConfig = INITIAL_FIELDS_CONFIG;

const optionsConfig = {
    onInput: resolveDependentFields(FIELDS.YEAR),
    fields: {
        [FIELDS.YEAR]: {
            onInput: resolveDependentFields(FIELDS.EMISSION),
        },
        [FIELDS.EMISSION]: {
            onInput: resolveDependentFields(FIELDS.MAKE),
        },
        [FIELDS.MAKE]: {
            onInput: resolveDependentFields(FIELDS.MODEL),
        },
        [FIELDS.MODEL]: {
            onInput: enableSearchButton,
        },
    },
};

const searchButton = {
    isDisabled: true,
};

const initialField = {
    ...INITIAL_FIELDS_CONFIG[FIELDS.APPLICATION_TYPE],
    name: FIELDS.APPLICATION_TYPE,
    getItems: getApplicationTypes,
    onInput: onApplicationTypeInput,
};

export default {
    fieldsConfig,
    optionsConfig,
    searchButton,
    initialField,
    setPartTypeData,
};
